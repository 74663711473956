// 参考代码 https://ads.tiktok.com/i18n/events_manager/v2/creationFlow/funnel/COGAPO3C77U9JEKSTP40?aadvid=7342388213543223297

import { sha256 } from 'js-sha256';

declare let ttq: any;

function identify(currentUser: any) {
  if (!currentUser) {
    return;
  }
  ttq.identify({
    "email": currentUser.email ? sha256(currentUser.email) : undefined, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
    "phone_number": currentUser.phone ? sha256(currentUser.phone) : undefined, // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
    "external_id": sha256(currentUser.id) // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
  });
}

function viewProduct(currentUser: any, productId: string, productName: string, price?: string | number){
  identify(currentUser);
  console.debug('adsTikTok:viewProduct', ...arguments);
  ttq.track('ViewContent', {
    "contents": [
      {
        "content_id": productId, // string. ID of the product. Example: "1077218".
        "content_type": "product", // string. Either product or product_group.
        "content_name": productName // string. The name of the page or product. Example: "shirt".
      }
    ],
    "value": price, // number. Value of the order or items sold. Example: 100.
    "currency": "USD" // string. The 4217 currency code. Example: "USD".
  });
}

function addToCart(currentUser: any, schema: any, product: any, quantity = 1){
  if (!schema || !product) {
    return;
  }
  identify(currentUser);
  console.debug('adsTikTok:addToCart', ...arguments);
  ttq.track('AddToCart', {
    "contents": [
      {
        "content_id": schema.goodsSchemeCode, // string. ID of the product. Example: "1077218".
        "content_type": "product", // string. Either product or product_group.
        "content_name": schema.schemeName || product.commodity_name // string. The name of the page or product. Example: "shirt".
      }
    ],
    "value": schema.schemePrice ? schema.schemePrice * quantity : 1, // number. Value of the order or items sold. Example: 100.
    "currency": "USD" // string. The 4217 currency code. Example: "USD".
  });
}

function placeOrder(currentUser: any, order: any) {
  if (!order) {
    return;
  }
  identify(currentUser);
  console.debug('adsTikTok:placeOrder', ...arguments);
  ttq.track('InitiateCheckout', {
    "contents": order.goodsList.map(g => ({
      content_id: g.sku,
      content_type: 'product',
      content_name: g.goodsName,
    })),
    "value": order.itemSubtotal, // number. Value of the order or items sold. Example: 100.
    "currency": "USD" // string. The 4217 currency code. Example: "USD".
  });
}

function checkout(currentUser: any, order: any) {
  if (!order) {
    return;
  }
  // identify(currentUser);
  //todo 待完善
  ttq.identify({
    "email": order.email ? sha256(order.email) : undefined, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
    "external_id": sha256(order.consumerId) // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
  });
  ttq.track('PlaceAnOrder', {
    "contents": order.children.map(g => ({
      content_id: g.goodsCode,
      content_type: 'product',
      content_name: g.goodsName,
    })),
    "value": order.payment, // number. Value of the order or items sold. Example: 100.
    "currency": "USD" // string. The 4217 currency code. Example: "USD".
  });
}

export function useAdsTikTok() {
  return {
    name: 'TikTok',
    viewProduct,
    addToCart,
    placeOrder,
    checkout
  }
}