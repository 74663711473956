declare module window {
  let uetq: any;
}

function sendEvent(event: 'add_to_cart' | 'begin_checkout' | 'purchase', ...args: any[]) {
  window.uetq = window.uetq || [];
  window.uetq.push(
    'event', 
    event, 
    ...args
  );
}

function addToCart(currentUser: any, schema: any, product: any, quantity = 1){
  if (!schema || !product) {
    return;
  }
  console.debug('adsBing:addToCart', ...arguments);
  sendEvent('add_to_cart', 
    {
      "revenue_value": schema.schemePrice ? schema.schemePrice * quantity : 1,
      "currency":"USD",
      "content_ids": [schema.goodsSchemeCode],
    }
  );
}


function placeOrder(currentUser: any, order: any) {
  if (!order) {
    return;
  }
  sendEvent('begin_checkout', {
    "revenue_value": order.itemSubtotal,
    "currency":"USD",
    "contents": order.goodsList.map(g => ({
      id: g.sku,
      quantity: g.quantity || 1,
    })),
  })
}

function checkout(currentUser: any, order: any) {
  if (!order) {
    return;
  }
  sendEvent('purchase', {
    "revenue_value": order.payment,
    "currency":"USD",
    "contents": order.children.map(g => ({
      id: g.goodsCode,
      quantity: g.quantity || 1,
    })),
  })
}

export function useAdsBing() {
  return {
    name: 'Bing',
    addToCart,
    placeOrder,
    checkout
  }
}