// <!-- Event snippet for MSBLUE INFO TEST PURCHASE conversion page -->
// <script>
//   gtag('event', 'conversion', {
//       'send_to': 'AW-10865757270/4B2sCJnlv6cZENaYmb0o',
//       'value': 1.0,
//       'currency': 'USD',
//       'transaction_id': ''
//   });
// </script>

// <!-- Event snippet for MSBLUE INFO ADD TO CART conversion page -->
// <script>
//   gtag('event', 'conversion', {
//       'send_to': 'AW-10865757270/Ss1ICP-476cZENaYmb0o',
//       'value': 1.0,
//       'currency': 'USD'
//   });
// </script>

// <!-- Event snippet for MSBLUE INFO PLACE ORDER conversion page -->
// <script>
//   gtag('event', 'conversion', {
//       'send_to': 'AW-10865757270/dCzMCIK576cZENaYmb0o',
//       'value': 1.0,
//       'currency': 'USD'
//   });
// </script>

// <!-- Event snippet for MSBLUE INFO VIEW PRODUCT conversion page -->
// <script>
//   gtag('event', 'conversion', {
//       'send_to': 'AW-10865757270/k1jiCIW576cZENaYmb0o',
//       'value': 1.0,
//       'currency': 'USD'
//   });
// </script>





const CONVERSION_SEND_TO = 'AW-10865757270/4B2sCJnlv6cZENaYmb0o';
const ADD_TO_CART_SEND_TO = 'AW-10865757270/Ss1ICP-476cZENaYmb0o';
const PLACE_ORDER_SEND_TO = 'AW-10865757270/dCzMCIK576cZENaYmb0o';
const VIEW_PRODUCT_SEND_TO = 'AW-10865757270/k1jiCIW576cZENaYmb0o';

function tagCurrentUser(currentUser: any) {
  if (currentUser?.email) {
    gtag('set', 'user_data', {
      email: currentUser.email
    });
    return true;
  }
  return false;
}


function viewProduct(currentUser: any, productId: string, productName: string, price: string | number, productTypeName: string){
  console.debug('adsGoogle:viewProduct', ...arguments);
  if (tagCurrentUser(currentUser)) {
   gtag('event', 'view_item', {
      currency: 'USD',
      value: price || 1,
      items: [
        {
          item_id: productId,
          item_name: productName,
          index: 0,
          item_category: productTypeName,
          price: price || 1
        }
      ]
    });
  } else {
    gtag('event', 'conversion', {
      send_to: VIEW_PRODUCT_SEND_TO,
      value: price || 1,
      currency: 'USD'
    });
  }
}

function checkout(currentUser: any, order: any) {
  if (!order) {
    return;
  }
  console.debug('adsGoogle:checkout', currentUser, order);
  gtag('set', 'user_data', {
    email: order.email
  });
  gtag('event', 'purchase', {
    currency: 'USD',
    value: order.payment,
    transaction_id: order.orderId,
    items: order.children.map(g => ({
      item_id: g.goodsCode,
      item_name: g.goodsName,
      quantity: g.quantity || 1,
    })),
  });
  gtag('event', 'conversion', {
    send_to: CONVERSION_SEND_TO,
    value: order.payment,
    currency: 'USD',
    transaction_id: order.id,
    contents: order.children.map(g => ({
      id: g.goodsCode,
      item_name: g.goodsName,
      quantity: g.quantity || 1,
    })),
  })
}


function addToCart(currentUser: any, schema: any, product: any, quantity = 1){
  if (!schema || !product) {
    return;
  }
  console.debug('adsGoogle:addToCart', ...arguments);
  tagCurrentUser(currentUser);
  gtag('event', 'add_to_cart', {
    currency: 'USD',
    value: schema.schemePrice ? schema.schemePrice * quantity : 1,
    items: [{
      item_id: schema.goodsSchemeCode,
      item_name: schema.schemeName || product.commodity_name,
      quantity: quantity
    }]
  });
  gtag('event', 'conversion', {
    send_to: ADD_TO_CART_SEND_TO,
    value: schema.schemePrice ? schema.schemePrice * quantity : 1,
    currency: 'USD',
    productId: schema.goodsSchemeCode,
    quantity: quantity
  })
}

function placeOrder(currentUser: any, order: any) {
  if (!order) {
    return;
  }
  console.debug('adsGoogle:placeOrder', ...arguments);
  tagCurrentUser(currentUser);
  gtag('event', 'begin_checkout', {
    currency: 'USD',
    value: order.itemSubtotal,
    items: order.goodsList.map(g => ({
      item_id: g.sku,
      item_name: g.goodsName,
      quantity: g.quantity || 1
    }))
  })

  gtag('event', 'conversion', {
    send_to: PLACE_ORDER_SEND_TO,
    value: order.itemSubtotal,
    currency: 'USD',
    contents: order.goodsList.map(g => ({
      id: g.sku,
      quantity: g.quantity || 1,
    })),
  })
}

export function useAdsGoogle() {
  return {
    name: 'Google',
    checkout,
    addToCart,
    placeOrder,
    viewProduct
  }
}