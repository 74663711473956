// 参考文档 https://developers.facebook.com/docs/meta-pixel/reference#reference

declare let fbq: any;

function viewProduct(currentUser: any, productId: string, productName: string, price: string | number, productTypeName: string){
  console.debug('adsFacebook:viewProduct', ...arguments);
  fbq('track', 'ViewContent', {
    "content_type": "product",
    "content_category": productTypeName,
    "content_name": productName,
    "content_ids": [productId],
    "contents": [
      {
        "id": productId, // string. ID of the product. Example: "1077218".
        "quantity": 1 // string. The name of the page or product. Example: "shirt".
      }
    ],
    "value": price || 1, // number. Value of the order or items sold. Example: 100.
    "currency": "USD" // string. The 4217 currency code. Example: "USD".
  });
}

function addToCart(currentUser: any, schema: any, product: any, quantity = 1){
  if (!schema || !product) {
    return;
  }
  console.debug('adsFacebook:addToCart', ...arguments);
  fbq('track', 'AddToCart', {
    "content_type": "product",
    "content_name": schema.schemeName || product.commodity_name,
    "content_ids": [schema.goodsSchemeCode],
    "contents": [
      {
        "id": schema.goodsSchemeCode,
        "quantity": quantity
      }
    ],
    "value": schema.schemePrice ? schema.schemePrice * quantity : 1,
    "currency": "USD"
  });
}

function placeOrder(currentUser: any, order: any) {
  if (!order) {
    return;
  }
  console.debug('adsFacebook:placeOrder', ...arguments);
  fbq('track', 'InitiateCheckout', {
    "content_type": 'product',
    "content_category": order.goodsList[0].goodsType,
    "contents": order.goodsList.map(g => ({
      id: g.sku,
      quantity: g.quantity || 1,
    })),
    "value": order.itemSubtotal,
    "currency": "USD"
  });
}

function checkout(currentUser: any, order: any) {
  if (!order) {
    return;
  }
  fbq('track', 'Purchase', {
    "content_type": 'product',
    "contents": order.children.map(g => ({
      id: g.goodsCode,
      quantity: g.quantity || 1,
    })),
    "value": order.payment,
    "currency": "USD"
  });
}

export function useAdsFacekbook() {
  return {
    name: 'Facekbook',
    viewProduct,
    addToCart,
    placeOrder,
    checkout
  }
}